// import { createConsumer } from '@rails/actioncable';
import { createCable } from '@anycable/web';

import { getENV } from 'utils/HelperUtils';

export const CableSocket = () => {
  const token = localStorage.getItem('bearer');

  let url = null;
  if (getENV('WEB_SOCKET_URL')) {
    url = getENV('WEB_SOCKET_URL');
  } else {
    url = getENV('PERFORMANCE_API_HOST');
  }

  url = url.replace('https', 'wss');

  let socketUrl = `${url}/cable`;
  if (token && token != 'undefined') {
    socketUrl = socketUrl + `?token=${token}`;
  }
  // const a = createConsumer(socketUrl)
  return createCable(window.Cypress ? 'ws://localhost:8013' : socketUrl);
};
