import React, { useEffect, useRef, useState } from 'react';

import ctl from '@netlify/classnames-template-literals';
import PropTypes from 'prop-types';

import { useLocale } from 'hooks/useLocale';

import SVGIcon from 'components/shared/SVGIcon';

const InputField = React.forwardRef((props, ref) => {
  const [offsetWidth, setOffSetWidth] = useState(0);
  const spanRef = useRef();
  const [isShowPasswordValue, setIsShowPasswordValue] = useState(false);
  const { setBothLocale } = useLocale();
  const typeInputField = isShowPasswordValue ? 'text' : 'password';

  const _onChange = (e) => {
    props.handleChange && props.handleChange(e);
  };

  const _onKeyPress = (e) => {
    props.handleKeyPress(e);
  };

  const inputCN = ctl(`
    ${props.styledInputClass}
    ${props.showBorderOnHover && 'show-border-on-hover'}
    ${props.inputClass && props.inputClass}
    ${props.bgColorClass ? props.bgColorClass : 'bg-n-000'}
    ${
      (props.disabled || props.readonly) &&
      'border-gray cursor-not-allowed border border-solid bg-n-100 text-n-800'
    }
    ${props.inputError && 'input-error'}
    ${props.cancelIcon && 'pr-[40px]'}
  `);

  const spanOffsetWidth = spanRef?.current?.offsetWidth;

  useEffect(() => {
    setOffSetWidth(spanOffsetWidth);
  }, [spanOffsetWidth]);

  return (
    <div
      className={`container-input ${props.className ? props.className : ''}`}
    >
      {props.label && (
        <label className="styled-label" htmlFor={props.htmlFor}>
          {setBothLocale(props.label)}
        </label>
      )}
      {props.search && (
        <SVGIcon
          size={24}
          fillColor="var(--base-600)"
          iconName="icon-search"
          customClass={
            props?.positionSearch
              ? props?.positionSearch
              : 'absolute left-8 top-[50%] -translate-y-1/2 transform'
          }
        />
      )}
      <div className={`flex items-center ${props.inputClassFooter}`}>
        <input
          ref={ref}
          className={inputCN}
          name={props.htmlFor}
          onChange={(!props.disabled || !props.readOnly) && _onChange}
          onKeyUp={props.handleKeyPress && _onKeyPress}
          id={props.htmlFor}
          type={props.password ? typeInputField : props.type}
          value={props?.value}
          defaultValue={props.defaultValue}
          placeholder={setBothLocale(props.placeholder)}
          data-cy={props.dataCy}
          disabled={props.disabled}
          readOnly={props.readonly}
          autoFocus={props.autoFocus}
          onKeyDown={props.onKeyDown}
          onBlur={props?.onBlur}
          onClick={props.onClick}
          autoComplete={props?.autoComplete}
          min={props.min}
          onFocus={props.onFocus}
          style={{
            width: props.useAutoGrowInput ? `${offsetWidth + 8}px` : '100%'
          }}
          onMouseDown={props.onMouseDown}
          onMouseUp={props.onMouseUp}
        />
        {props.useAutoGrowInput && (
          <span
            ref={spanRef}
            className={`invisible ${inputCN}`}
            style={{
              width: 'fit-content',
              height: '0px',
              paddingTop: '0px',
              paddingBottom: '0px',
              borderTop: '0px',
              borderBottom: '0px'
            }}
          >
            {props?.value}
          </span>
        )}
        {props.password && (
          <SVGIcon
            iconName={
              isShowPasswordValue
                ? 'icon-remove_red_eye'
                : 'icon-disabled_red_eye'
            }
            fillColor="var(--n-600)"
            onClick={() => setIsShowPasswordValue(!isShowPasswordValue)}
            size={isShowPasswordValue ? '20' : '24'}
            customClass={`absolute ${
              isShowPasswordValue ? 'right-[14px]' : 'right-[12px]'
            } `}
          />
        )}

        {props.calendar && (
          <SVGIcon
            iconName="icon-calendar_today"
            fillColor={
              props.disabled || props.readonly
                ? 'var(--n-600)'
                : 'var(--base-600)'
            }
            size="24"
            customClass="absolute right-[8px]"
          />
        )}
        {props.cancelIcon && (
          <SVGIcon
            iconName="icon-cancel"
            fillColor="var(--n-600)"
            onClick={() => props.cancelChanges()}
            size="24"
            disabled={props.disabled}
            customClass="absolute right-[8px]"
          />
        )}
      </div>
    </div>
  );
});

InputField.propTypes = {
  htmlFor: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  handleKeyPress: PropTypes.func,
  dataCy: PropTypes.string,
  styledInputClass: PropTypes.string
};

InputField.defaultProps = {
  styledInputClass: 'styled-input'
};

InputField.displayName = 'InputField';

export default InputField;
