"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCSSVariable = exports.getTailwindBorderColorClass = exports.getTailwindBgColorClass = exports.getTailwindTextColorClass = exports.getTailwindColorClass = exports.getColorName = exports.fullColorDict = exports.colorDict = exports.colorsHex = exports.EColorPicker = void 0;
const HelperUtils_1 = require("./HelperUtils");
var EColorPicker;
(function (EColorPicker) {
    EColorPicker["PINK"] = "EC4899";
    EColorPicker["CRIMSON"] = "F43F5E";
    EColorPicker["AMBER"] = "F59E0B";
    EColorPicker["LIME"] = "84CC16";
    EColorPicker["TEAL"] = "14B8A6";
    EColorPicker["LIGHT_BLUE"] = "0EA5E9";
    EColorPicker["INDIGO"] = "6366F1";
    EColorPicker["VIOLET"] = "6D3AF8";
    EColorPicker["ORANGE"] = "F97316";
    EColorPicker["EMERALD"] = "10B981";
    EColorPicker["CYAN"] = "06B6D4";
    EColorPicker["FUCHSIA"] = "D946EF";
    EColorPicker["RED"] = "EF4444";
    EColorPicker["YELLOW"] = "EAB308";
    EColorPicker["GREEN"] = "22C55E";
    EColorPicker["BLUE"] = "3B82F6";
})(EColorPicker = exports.EColorPicker || (exports.EColorPicker = {}));
exports.colorsHex = {
    '#5417CF': 'v',
    '#DB2777': 'p',
    '#E11D48': 'c',
    '#D97706': 'a',
    '#EA580C': 'o',
    '#65A30D': 'l',
    '#059669': 'e',
    '#0D9488': 't',
    '#0891B2': 'cy',
    '#0284C7': 'lb',
    '#4F46E5': 'i',
    '#C026D3': 'f'
};
exports.colorDict = {
    EC4899: 'p',
    F43F5E: 'c',
    F59E0B: 'a',
    '84CC16': 'l',
    '14B8A6': 't',
    '0EA5E9': 'lb',
    '6366F1': 'i',
    '6D3AF8': 'v',
    F97316: 'o',
    '10B981': 'e',
    '06B6D4': 'cy',
    D946EF: 'f',
    EF4444: 'r',
    EAB308: 'y',
    '22C55E': 'g',
    '3B82F6': 'b'
};
exports.fullColorDict = {
    EC4899: 'Pink',
    F43F5E: 'Crimson',
    F59E0B: 'Amber',
    '84CC16': 'Lime',
    '14B8A6': 'Teal',
    '0EA5E9': 'Light Blue',
    '6366F1': 'Indigo',
    '6D3AF8': 'Violet',
    F97316: 'Orange',
    '10B981': 'Emerald',
    '06B6D4': 'Cyan',
    D946EF: 'Fuchsia',
    EF4444: 'Red',
    EAB308: 'Yellow',
    '22C55E': 'Green',
    '3B82F6': 'Blue'
};
const getColorName = (hex) => {
    return exports.fullColorDict[hex] || '';
};
exports.getColorName = getColorName;
const getTailwindColorClass = ({ hexCode, classPrefix, shade = 700 }) => {
    let alias = 'n';
    if (hexCode && exports.colorDict[hexCode]) {
        alias = exports.colorDict[hexCode];
    }
    return `${classPrefix}-${alias}-${shade}`;
};
exports.getTailwindColorClass = getTailwindColorClass;
const getTailwindTextColorClass = ({ hexCode, shade }) => {
    return (0, exports.getTailwindColorClass)({ hexCode, classPrefix: 'text', shade });
};
exports.getTailwindTextColorClass = getTailwindTextColorClass;
const getTailwindBgColorClass = ({ hexCode, shade = 100 }) => {
    return (0, exports.getTailwindColorClass)({ hexCode, classPrefix: 'bg', shade });
};
exports.getTailwindBgColorClass = getTailwindBgColorClass;
const getTailwindBorderColorClass = ({ hexCode, shade = 200 }) => {
    return (0, exports.getTailwindColorClass)({ hexCode, classPrefix: 'border', shade });
};
exports.getTailwindBorderColorClass = getTailwindBorderColorClass;
const getCSSVariable = ({ hexCode, shade = 700 }) => {
    const alias = hexCode ? exports.colorDict[hexCode] : 'n';
    const _shade = alias === 'n' ? 800 : shade;
    return (0, HelperUtils_1.getCssVariableValue)(`--${alias}-${_shade}`);
};
exports.getCSSVariable = getCSSVariable;
